$main-color: #3498db;
$secondary-color: #d8d8d8;
$light-color: #eeeeee;
$hover-color: #5dade2;
$danger-color: #f5b041;
$danger-color2: #dc3545;
$success-color: #a7d08c;
$header-color: #e5e7e9;

// stat-table
$main-blue-color: #9cc7e9;
$light-blue-color: #eaeef7;
$green-color: #a7d08c;
$card-bg-color: #f2f2f2;
