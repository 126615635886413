.container {
  max-width: 1100px;
  height: 90%;
  margin: 0 auto;
  padding: 5px 10px 30px 10px;
}

h4 {
  margin-bottom: 5px;
}

input {
  margin: 5px 0;
  padding: 3px 10px;
  height: 1.5rem;
}

select {
  margin: 5px 0;
  padding: 3px 10px;
  height: 2rem;
}

.btn {
  margin: 5px 0;
  padding: 7px;
  border: 0;
  border-radius: 0.3rem;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  &-block {
    display: block;
  }
  &-main {
    background: $main-color;
    color: $light-color;
  }
  &-light {
    background: $secondary-color;
    color: #000;
    &:hover {
      background: #fff;
      color: $main-color;
    }
    &-disabled {
      background: #EEEEEE;
      color: #D0D0D0;
    }
  }
  &-danger {
    background: $danger-color2;
    color: #fff;
  }
  &-success {
    background: $success-color;
    color: #000;
  }
  &:hover {
    opacity: 0.8;
  }
}

.alert {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 0.7rem;
  width: 300px;
  border-radius: 5px;
  margin: 1rem auto;
  opacity: 0.9;
  color: #000;
  animation: alert 3s forwards ease-out;
  &-success {
    background: $success-color;
  }
  &-danger {
    background: $danger-color;
  }
}

@keyframes alert {
  from {
    bottom: 20px;
    opacity: 1;
  }
  to {
    bottom: 60px;
    opacity: 0;
  }
}

.no-match {
  padding: 15px;
  background: $secondary-color;
  text-align: center;
}

.bold {
  font-weight: bold;
}

table {
  width: 100%;
  margin: 15px auto;
  text-align: center;
  border-collapse: collapse;
  thead {
    background: $header-color;
  }
  td,
  th {
    border: 1px solid $header-color;
    padding: 5px;
    font-size: 0.7rem;
  }
}

.tag {
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  background-color: #FAFAFA;
  padding: 4px 8px;
  margin-left: 16px;
}
