@import 'variables';
@import 'utilities';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Navbar
nav {
  background: $main-color;
  color: $light-color;
  width: 100%;
  font-size: 0.8rem;

  div.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 5px;

    #menu {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
      text-align: center;
      width: 100%;
      #nav-login {
        width: 100px;
      }
      //.dropdown {
      //  margin-left: 0.5rem;
      //  display: none;
      //  #nav-dropdown {
      //    color: #fff;
      //    background: none;
      //  }
      //  .dropdown-menu {
      //    .dropdown-item {
      //      padding: 0;
      //      span {
      //        display: inline-block;
      //        padding: 0.5rem;
      //        font-size: 0.8rem;
      //        color: inherit;
      //      }
      //      &:nth-child(3),
      //      &:nth-child(4) {
      //        display: none;
      //      }
      //      &:hover,
      //      &:focus,
      //      &:active {
      //        background: inherit;
      //        a {
      //          color: $main-color;
      //        }
      //      }
      //      a {
      //        display: block;
      //        width: 100%;
      //        font-size: 0.8rem;
      //        color: rgb(135, 135, 135);
      //        padding: 0.5rem 0 0.5rem 0.5rem;
      //        &:hover {
      //          background: none;
      //          color: $main-color;
      //        }
      //      }
      //    }
      //  }
      //}
    }

    a#logo {
      &:hover {
        background: none !important;
      }
      .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        h3 {
          white-space: nowrap;
        }

        i {
          margin-right: 10px;
        }
      }
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      padding: 0;
      // margin-right: 50px;
    }
    a {
      display: inline-block;
      width: fit-content;
      padding: 10px;
      text-decoration: none;
      color: $light-color;
      cursor: pointer;
      &:hover {
        background: $hover-color;
        color: #000;
      }
    }
    p {
      color: #ecf0f1;
      // margin-left: 40px;
    }
  }
}

#goTelegram {
  z-index: 100;
  background-color: $success-color;
  opacity: 1;
  width: 250px;
  position: fixed;
  bottom: 0;
  right: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  i {
    margin: 5px 5px 5px 0;
  }
}

// Spinner linear
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: $hover-color;
  font-size: 10px;
  margin: 10px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

// login
#login {
  form {
    margin: 0 auto;
    width: 300px;
    display: flex;
    flex-direction: column;
  }
  h4 {
    text-align: center;
  }
}

// TICKETS FILTER
div.form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

form.filters {
  width: 300px;
  display: flex;
  flex-direction: column;
  + button {
    width: 300px;
  }
}

#ticket-filter {
  width: 300px;
  display: flex;
  flex-direction: column;
  + button {
    width: 300px;
  }
}

#img {
  text-align: center;
  a {
    text-decoration: none;
    display: block;
    width: 100px;
    height: 30px;
    margin: 5px auto;
    padding: 5px;
    &:hover {
      background: $main-color;
      color: $light-color;
    }
  }
}

#archive {
  form {
    margin: 0;
    width: 300px;
    display: flex;
    flex-direction: column;
  }
  h4 {
    text-align: center;
  }
}

#archive-table {
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  tr:nth-child(1) {
    background: $header-color;
    td {
      font-weight: bold;
      padding: 10px;
      font-size: 0.8rem;
      border: 1px solid #ccc;
    }
  }
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  div {
    min-width: 285px;
    font-size: 0.8rem;
    padding: 1rem;
    margin-top: 10px;
    border-radius: 1rem;
    a.download {
      text-decoration: none;
      display: inline-block;
      width: 100%;
      margin-top: 5px;
      padding: 3px;
    }
    table {
      .normal-text {
        font-weight: normal !important;
      }
      font-size: 1rem;
      font-weight: 500;
      text-align: left;
      border: none;
      tr,
      td {
        border: none;
        padding: 5px 2px;
        // border-bottom: 1px solid #eeeeee;
      }
      tr:nth-child(3) {
        background-color: $main-blue-color;
        border-bottom: 2px solid black;
        td {
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
        }
      }
      tr:nth-child(5),
      tr:nth-child(6),
      tr:nth-child(7),
      tr:nth-child(11) {
        background-color: #d9e2f3;
      }
      tr:nth-child(12),
      tr:nth-child(16),
      tr:nth-child(17) {
        background-color: rgb(217, 240, 212);
      }
      tr:nth-child(8) td:first-child,
      tr:nth-child(9) td:first-child,
      tr:nth-child(10) td:first-child,
      tr:nth-child(13) td:first-child,
      tr:nth-child(14) td:first-child,
      tr:nth-child(15) td:first-child {
        text-align: right;
      }
      tr:nth-child(13) td:nth-child(2),
      tr:nth-child(14) td:nth-child(2),
      tr:nth-child(15) td:nth-child(2) {
        font-weight: 500;
      }
      tr:nth-child(16) {
        border-bottom: 2px solid black;
      }
      tr:nth-child(8),
      tr:nth-child(9),
      tr:nth-child(10),
      tr:nth-child(13),
      tr:nth-child(14),
      tr:nth-child(15) {
        font-weight: normal;
      }
    }
  }
}

// Modal

.modal-main {
  position: fixed;
  background: white;
  width: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: 0.5rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

#files {
  list-style: none;
  width: 70%;
  min-width: 250px;
  margin: 2rem auto;
  li {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid $light-color;
    a {
      text-decoration: none;
      visibility: hidden;
      margin-left: 0.5rem;
    }
    &:hover,
    &:focus {
      background-color: $hover-color;
      color: #fff;
      a {
        visibility: visible;
        color: #fff;
        &:hover,
        &:focus {
          color: rgb(245, 228, 0);
          cursor: pointer;
        }
      }
    }
  }
  li:last-child {
    border-bottom: none;
  }
}

@media (max-width: 950px) {
  nav {
    div.container #menu .dropdown {
      display: block;
    }
    #logout-btn {
      i {
        font-size: 1rem;
      }
      span {
        display: none;
      }
    }
  }
}

// responsive
@media (max-width: 768px) {
  nav {
    height: auto;
    width: 100%;

    div.container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .logo {
        max-width: 10px auto !important;
      }

      h3 {
        margin: 5px auto;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;

      }
      a {
        display: block;
        padding: 5px 10px;
      }
    }
  }
  div.form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }
  form.filters {
    width: 200px;
    display: flex;
    flex-direction: column;
    + button {
      width: 200px;
    }
  }

  #ticket-filter {
    width: 200px;
    display: flex;
    flex-direction: column;
    + button {
      width: 200px;
    }
  }
}

// responsive
@media (max-width: 600px) {
  nav {
    height: auto;
    width: 100%;
  }
  div.form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }
  form.filters {
    width: 150px;
    display: flex;
    flex-direction: column;
    + button {
      width: 150px;
    }
  }

  #ticket-filter {
    width: 150px;
    display: flex;
    flex-direction: column;
    + button {
      width: 150px;
    }
  }
}

.refresh-logs {
  color: $main-color;
  cursor: pointer;
  transition: transform 1s;
  &:hover {
    color: $success-color;
    transform: rotate(360deg);
  }
}
#reset-logs {
  display: inline-block;
  margin-left: 20px;
  color: $danger-color;
  cursor: pointer;
  transition: transform 1s;
  &:hover {
    color: $main-color;
    transform: rotate(360deg);
  }
}

#archive-download {
  cursor: pointer;
  position: fixed;
  border-radius: 50px;
  color: $main-color;
  bottom: 30px;
  left: 30px;
  &:hover {
    color: $hover-color;
  }
}

.fixed-spinner {
  width: 100%;
  position: absolute;
  top: 45vh;
  left: 0;
}

#stat-table {
  max-width: 600px;
  min-width: 300px;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
  table {
    // thead {
    //   background: none !important;
    //   padding-bottom: 10px;
    //   th {
    //     border: none;
    //     padding-top: 0.5rem;
    //     padding-bottom: 0.5rem;
    //   }
    // }
    tbody {
      td {
        border: none;
        padding: 0.2rem 1rem;
      }
      tr {
        td:first-child {
          text-align: right;
        }
        &:nth-child(23),
        &:nth-child(24) {
          td:first-child {
            text-align: left !important;
            font-style: italic;
          }
        }
      }
      tr:nth-child(1) {
        background-color: $main-blue-color;
      }
      tr:nth-child(2),
      tr:nth-child(13),
      tr:nth-child(17),
      tr:nth-child(20) {
        background-color: $light-blue-color;
      }
      tr:nth-child(1) td,
      tr:nth-child(2) td,
      tr:nth-child(3) td,
      tr:nth-child(9) td,
      tr:nth-child(13) td,
      tr:nth-child(17) td,
      tr:nth-child(20) td {
        font-size: 0.9rem !important;
        font-weight: bold;
        text-align: left;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }
}

#main-page {
  h4 {
    margin-bottom: 1rem;
  }
  #top {
    border-bottom: 2px dashed $secondary-color;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    h5 {
      margin-top: 0;
    }
    > div {
      padding: 1rem;
    }
    table {
      thead {
        background: none;
      }
      td,
      th {
        border: none;
        i {
          font-size: 1.5rem;
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.2) rotate(360deg);
          }
        }
      }
    }
    .list-group {
      margin-left: 1rem;
      margin-right: 1rem;
      &-item {
        font-size: 0.7rem;
        line-height: 1rem;
        i {
          font-size: 1rem;
          margin-right: 0.7rem;
          margin-bottom: 0.15rem;
          color: $main-color;
          transition: all 0.4s ease-in-out;
        }
        &:hover {
          background-color: $light-color;
          i {
            transform: rotate(50deg);
          }
        }
      }
    }
  }
  #bottom {
    display: grid;
    //grid-template-columns: 5fr 3fr;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    max-width: 800px;
    margin: 0 auto;
    > div {
      padding: 1rem;
    }
    .card {
      margin-bottom: 1rem;
      border-radius: 0.25rem;
      box-shadow: none;
      border: 1px solid $secondary-color;
      font-size: 0.8rem;
      &-header {
        background: $light-color;
        &:hover {
          background-color: $secondary-color;
        }
      }
    }
  }
  @media (max-width: 756px) {
    #top {
      grid-template-columns: 1fr;
    }
    #bottom {
      grid-template-columns: 1fr;
      iframe {
        width: 400px;
      }
    }
  }
}
