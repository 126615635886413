@import "src/variables";

.billHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
}

.billInfo {
  margin-top: 16px;
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  padding: 12px;

  & > div {
    margin: 0 12px 12px 24px;
  }
}

.back {
  horiz-align: center;
  font-size: 16px;
  margin-right: 16px;
  cursor: pointer;

  &:hover {
    color: $main-color;
  }
}

.billActions {
  display: flex;
  gap: 16px;
  align-items: center;
}
