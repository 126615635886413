@import './src/variables';

.container {
  position: sticky;
  display: flex;
  margin-top: 16px;
  gap: 32px;
}

.arrowRight {
  font-size: 20px;
  line-height: 0;
  margin: 0 4px;
}

.selectStatus {
  height: 24px;
}

.clearDate {
  margin-left: 4px;
  cursor: pointer;

  &:hover {
    color: $main-color
  }
}
