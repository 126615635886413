.head {
  position: sticky;

  & th {
    font-size: 14px;
  }
}

.tableRow {
  &:hover {
    background-color: rgba(229, 231, 233, 0.3);
    cursor: pointer;
  }
  & td {
    font-size: 12px;
    padding: 12px;
  }
}