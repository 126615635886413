.modalTitle {
  margin-bottom: 18px;
  font-weight: 500;
}

.accordion {
  margin-top: 12px;
}

.card {
  box-shadow: none !important;
}

.cardHeader {
  cursor: pointer;
  padding: 0 !important;
}

.cardBody {
  padding: 0 !important;
}

.table {
  margin: 0 !important;
}

.plus {
  color: #3498DB;
  margin-right: 5px;
}

.table {
  display: grid;
  grid-template-columns: repeat(2, 1fr) repeat(2, 15%);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  & > div {
    border: 1px solid #e5e7e9;
    padding: 0.75rem 1.25rem;
  }
}

.tableTitle {
  grid-area: 1 / 1 / 2 / 2;
}

.tableDescription {
  grid-area: 1 / 2 / 2 / 3;
}

.tableLength {
  grid-area:  1 / 3 / 2 / 4;
}

.tableTotal {
  grid-area: 1 / 4 / 2 / 5;
}
